import PropTypes from "prop-types";
import React from "react";
import { StaticQuery, graphql } from "gatsby";

const BotContainer = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allBotJson {
          edges {
            node {
              id
              pageTitle
              pageBanner {
                hideSection
                textContent {
                  heading
                  content
                }
                backgroundImage
              }
              introduction {
                actionButtons {
                  buttonLabel
                  buttonPath
                  buttonType
                  externalLink
                  hideButton
                  simpleLink
                }
                images {
                  image
                }
                hideSection
                textContent {
                  content
                  heading
                }
              }
              summary {
                hideSection
                textContent {
                  content
                  heading
                }
                actionButtons {
                  buttonLabel
                  buttonPath
                  buttonType
                  externalLink
                  hideButton
                  simpleLink
                }
                backgroundImage
              }
              details {
                sectionHeading
                phases {
                  content
                  heading
                  hideCard
                  projectImage
                  actionButtons {
                    buttonLabel
                    buttonPath
                    buttonType
                    externalLink
                    hideButton
                    simpleLink
                  }
                }
              }
              ctaSection {
                hideSection
                textContent {
                  heading
                  content
                }
                actionButtons {
                  hideButton
                  simpleLink
                  externalLink
                  buttonType
                  buttonLabel
                  buttonPath
                }
              }
            }
          }
        }
      }
    `}
    render={({ allBotJson: { edges: botData } }) =>
      children(
        botData.map((e) => ({
          ...e.node,
        }))
      )
    }
  />
);

BotContainer.propTypes = {
  children: PropTypes.func.isRequired,
};

export default BotContainer;
