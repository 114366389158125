import React from "react";
import { Router } from "@reach/router";
import BotPage from "../components/BotPage";
import NotFoundPage from "./404";

const botPageRouter = (props) => (
  <Router>
    <BotPage {...props} path="/bot" />
    <NotFoundPage default />
  </Router>
);

export default botPageRouter;
