import React from "react";
import { paramCase } from "change-case";
import PropTypes from "prop-types";
import SEO from "../SEO/seo";
import BotContainer from "../../containers/BotContainer";
import PageBanner from "../../sections/PageBanner";
import PageIntro from "../../sections/PageIntro";
import HorizontalCardHalfImage from "../../sections/HorizontalCardHalfImage";
import Heading from "../Heading";

const BotPage = () => (
  <BotContainer>
    {(botData) => {
      const botContent = botData.find((data) => data.id === "bot");
      const { pageBanner, introduction, summary, details, ctaSection } =
        botContent;
      const { phases } = details;

      return (
        <>
          <SEO pathname={botContent.id} templateTitle={botContent.pageTitle} />
          {pageBanner && (
            <PageBanner
              bannerContent={pageBanner.textContent}
              bgImage={pageBanner.backgroundImage}
              contentSx={{ width: ["100%", null, null, "60%"] }}
              sx={{ minHeight: "25rem" }}
            />
          )}
          {introduction && !introduction.hideSection && (
            <PageIntro
              contentWrapperSx={{ width: [null, null, null, "60%"] }}
              data={introduction}
              imageRatio={[1, 1]}
              imageSx={{
                maxWidth: "100%",
              }}
              imageWrapperSx={{
                maxWidth: ["100%", null, null, "40%"],
                mt: [0, null, null, "-15rem"],
              }}
              page={botContent.id}
            />
          )}
          {summary && !summary.hideSection && (
            <PageIntro
              containerSx={{
                maxWidth: [
                  null,
                  "maxWidths.sm",
                  "maxWidths.md",
                  "maxWidths.lg",
                  "maxWidths.xl",
                ],
              }}
              contentSx={{
                ".md_content": {
                  columnCount: [null, null, null, 2],
                  mt: [2, null, null, 4],
                  ul: { li: { mb: 1 }, mt: [null, null, null, 0] },
                },
                color: "white",
                display: "flex",
                flexDirection: "column",
                h2: {
                  color: "primaryLight",
                  fontSize: 5,
                },
              }}
              contentWrapperSx={{
                width: "100%",
              }}
              data={summary}
              page={botContent.id}
              sectionSx={{
                bg: "primary",
                mt: [5, null, null, null, 6],
              }}
            />
          )}
          {details.sectionHeading && (
            <Heading
              as="h3"
              sx={{
                bg: "white",
                fontFamily: "secondary",
                fontSize: 2,
                letterSpacing: 4,
                lineHeight: 3,
                pt: [5, null, null, 8],
                textAlign: "center",
                textTransform: "uppercase",
              }}
            >
              {details.sectionHeading}
            </Heading>
          )}
          {phases &&
            phases
              .filter((phase) => !phase.hideCard)
              .map((phase, index) => (
                <HorizontalCardHalfImage
                  key={`${paramCase(phase.heading)}-${index}`}
                  data={{
                    image: phase?.projectImage,
                    textContent: {
                      content: phase?.content,
                    },
                  }}
                  imageSx={{ top: 0 }}
                  isEven={(index + 1) % 2 === 0}
                  page={botContent.id}
                  sectionSx={{ bg: "white" }}
                  textContentSx={{
                    h2: { color: "black" },
                  }}
                />
              ))}

          {ctaSection && !ctaSection.hideSection && (
            <PageIntro
              contentSx={{
                h2: {
                  color: "primary",
                  fontSize: 5,
                },
              }}
              contentWrapperSx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                pr: 0,
                textAlign: "center",
                width: "100%",
              }}
              data={ctaSection}
              page="industry"
              sectionSx={{
                bg: "grays.0",
              }}
            />
          )}
        </>
      );
    }}
  </BotContainer>
);

BotPage.propTypes = {
  location: PropTypes.shape({
    hash: PropTypes.string,
  }),
};

BotPage.defaultProps = {
  location: null,
};

export default BotPage;
