import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { navigate } from "gatsby";
import PageBanner from "../sections/PageBanner";
import Button from "../components/Button";
import AspectRatio from "../components/AspectRatio";
import Image from "../components/Image";

const NotFoundPage = () => {
  const theme = useContext(ThemeContext);
  return (
    <>
      <PageBanner
        bannerContent={{
          content: "### You just hit a route that doesn&#39;t exist.",
          heading: "## 404: Page Not Found",
        }}
        containerSx={{
          justifyContent: "center",
          mb: "auto",
          pt: 4,
          textAlign: "center",
        }}
        contentChildren={
          <Button
            onClick={() => {
              navigate("/");
            }}
            sx={{ alignItems: "center", display: "inline-flex", my: 3 }}
            variant="buttons.secondary"
          >
            Go to Home
            <AspectRatio sx={{ color: "inherit", ml: 2, width: "2rem" }}>
              <Image svgIcon="arrow-right" />
            </AspectRatio>
          </Button>
        }
        contentSx={{
          "h2, h3": {
            color: "white",
          },
        }}
        sx={{ backgroundImage: theme.baseGradients.primary[0] }}
      />
    </>
  );
};

export default NotFoundPage;
